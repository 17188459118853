import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import useStateRef from 'react-usestateref'

export type RenderModal<T = boolean> = (resolve: (val?: T) => void, customProps?: any) => React.ReactNode

export type ShowModal = <T = boolean>(renderModal: RenderModal<T>, key: string) => Promise<T>

export interface ModalContextValue {
  showModal: ShowModal
  updateModal: (key: string, children: React.ReactNode) => void
  closeModal: (key: string) => void
  isOpen: (key: string) => boolean
  blockModalEscape: () => void
  unblockModalEscape: () => void
  escapeBlocked: boolean
  bypassFocusLock: boolean
  setBypassFocusLock: (val: boolean) => void
}

/**
 * @deprecated
 * @private
 * use `useModal` instead
 *
 * deprecated used for decoration in vscode
 * remove it when all useContext(ModalContext) are changed to useModal()
 */
export const ModalContext = React.createContext<ModalContextValue | undefined>(undefined)

export const ModalContextProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const [modals, setModals, modalsRef] = useStateRef<Record<string, React.ReactNode>>({})
  const [bypassFocusLock, setBypassFocusLock] = useStateRef(false)

  const escapeBlockedRef = useRef(false)
  const resolver = useRef<Record<string, (value: any | PromiseLike<any>) => void>>({})
  const escapeBlocked = escapeBlockedRef.current
  const intercomCheckIntervalRef = useRef<NodeJS.Timeout>()

  const blockEscape = useCallback(() => {
    escapeBlockedRef.current = true
  }, [])

  const unblockEscape = useCallback(() => {
    escapeBlockedRef.current = false
  }, [])

  useEffect(() => {
    intercomCheckIntervalRef.current = setInterval(addIntercomEventListeners, 1000)
  }, [])

  const addIntercomEventListeners = useCallback(() => {
    if (!window.Intercom) return
    window.Intercom('onHide', () => setBypassFocusLock(false))
    window.Intercom('onShow', () => setBypassFocusLock(true))
    intercomCheckIntervalRef.current && clearInterval(intercomCheckIntervalRef.current)
  }, [])

  const handleShow: ShowModal = useCallback((renderModal, key) => {
    const prev = modalsRef.current
    setModals({
      ...prev,
      [key]: renderModal((val = true) => {
        resolver.current[key]?.(val)
        delete resolver.current[key]
        setModals((prev) => {
          const updated = { ...prev }
          delete updated[key]
          return updated
        })
      }),
    })

    return new Promise<any>((resolve) => {
      resolver.current[key] = resolve
    })
  }, [])

  const closeModal = useCallback((key: string) => {
    delete resolver.current[key]
    const prev = modalsRef.current
    const updated = { ...prev }
    delete updated[key]
    setModals(updated)
  }, [])

  const updateModal = useCallback((key: string, children: React.ReactNode) => {
    const prev = modalsRef.current
    const updated = { ...prev }
    updated[key] = children
    setModals(updated)
  }, [])

  const isOpen = useCallback((key: string) => !!modalsRef.current?.[key], [])

  const children = useMemo(() => props.children, [props.children])

  const value = useMemo(
    () => ({
      showModal: handleShow,
      updateModal: updateModal,
      blockModalEscape: blockEscape,
      unblockModalEscape: unblockEscape,
      closeModal,
      isOpen,
      escapeBlocked,
      bypassFocusLock,
      setBypassFocusLock,
    }),
    [
      handleShow,
      updateModal,
      blockEscape,
      unblockEscape,
      closeModal,
      isOpen,
      escapeBlocked,
      bypassFocusLock,
      setBypassFocusLock,
    ],
  )

  return (
    <ModalContext.Provider value={value}>
      <ChildWrapper children={children} />

      {Object.entries(modals).map(([key, modal]) => (
        <div key={key}>{modal}</div>
      ))}
    </ModalContext.Provider>
  )
}

const ChildWrapper = React.memo(({ children }: { children: React.ReactNode }) => <>{children}</>)

export function withModalContext<T>(Component: React.ComponentType<T>) {
  return (props: T) => (
    <ModalContext.Consumer>
      {(context) => <Component modalContext={context} {...props} />}
    </ModalContext.Consumer>
  )
}

export const useModal = () => {
  const context = React.useContext(ModalContext)

  if (!context) {
    throw 'You sure component tree is wrapped in ModalContext.Provider?'
  }

  return context
}
