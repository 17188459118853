import { Plugin } from 'ckeditor5'

declare global {
  interface DocumentEventMap {
    'ckeditor-custom-text': CustomEvent;
  }
}
export default class AddTextToEditor extends Plugin {
  init() {
    document.addEventListener(
      'ckeditor-custom-text',
      (event: CustomEvent) => {
        event.stopImmediatePropagation()
        console.log('ckeditor-custom-text event received', event.detail)
        // this.editor.enableReadOnlyMode('adding-external-text')
        this.editor.model.change((writer) => {
          if (event.detail.replace) {
            // this.editor.data.set(event.detail.body, { suppressErrorInCollaboration: true })
            this.editor.execute('selectAll')
            this.editor.model.document.selection._ranges.forEach((range) => {
              writer.remove(range)
            })
          }
          const position = this.editor.model.document.selection.getLastPosition()
          if (position) {
            const viewFragment = this.editor.data.processor.toView(event.detail.body)
            const modelFragment = this.editor.data.toModel(viewFragment)
            this.editor.model.insertContent(modelFragment)
            // writer.insertContent(event.detail.body, position)
          } else {
            console.log('we don;t have a position to insert text')
          }
        })
        // this.editor.disableReadOnlyMode('adding-external-text')
      },
      { once: true },
    )
  }
}
